<template>
<div>
  <rel-header>
    <form>
      <e-row mr>
        <e-col>
          <e-row class="large-mr erp-s-field-bold" mr>
            <e-col style="max-width: 140px">
              <erp-s-field label="Data inicial:">
                <datetime-modern-input v-model="filters.data1" date-type="date" date-format="##/##/####" />
              </erp-s-field>
            </e-col>
            <e-col style="max-width: 140px">
              <erp-s-field label="Data final:">
                <datetime-modern-input v-model="filters.data2" date-type="date" date-format="##/##/####" />
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field label="Colaborador:">
                <global-pessoa-input type="user" v-model="filters.colaborador" layout="float-icon input-hide-loading" :hide-underline="false" />
              </erp-s-field>
            </e-col>
          </e-row>
        </e-col>
        <e-col style="max-width: 120px; text-align: right;" class="flex items-center justify-end">
          <erp-s-field label="">
            <u-btn label="Gerar" color="green" :loading="gerando" @click="gerar" />
          </erp-s-field>
        </e-col>
      </e-row>
    </form>
  </rel-header>
  <div v-if="resultado" class="sl-body">
    <div class="base-table">
      <table>
        <thead>
        <tr>
          <th class="row1-table"><span>Colaborador</span></th>
          <th v-for="d in resultado.periodo">{{ convertDate(d) }}</th>
          <th>Total</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="user in resultado.workedHours">
          <td>
            <div @click="clickPerson(user.person)" class="flex items-center cursor-pointer">
            <div
                 class="person-avatar avatar inline hover-scale min">
              <img v-if="user.person.photo" width="30" class="" :src="user.person.photo"/>
              <div v-else class="img-fake">{{ firstLettersName(user.person.name) }}</div>
            </div>
            <span class="m-l-xs">{{ user.person.name }}</span>
            </div>
          </td>
          <td v-for="periodo in resultado.periodo">{{ typeof user.workedHours[periodo] !== 'undefined' ? secondsToHours(user.workedHours[periodo].seconds) : '0' }}h</td>
          <td>{{ secondsToHours(user.workedHoursTotal) }}h</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import RelHeader from '@/components/relatorios/layout/RelHeader'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import {ErpSField} from "uloc-vue-plugin-erp"
import DatetimeModernInput from "@/reuse/input/DatetimeModern"
import GlobalPessoaInput from "@/components/pessoa/components/include/pessoa/PessoaInput"
import {relatorio} from "@/domain/relatorios/services"
import {datePtToEn} from "@/utils/date"
import GlobalPersonMixin from "components/pessoa/globalPersonMixin"
import {formatDistance} from "date-fns";
import ptBRLocale from "date-fns/locale/pt-BR"

export default {
  name: "TempoTrabalho",
  mixins: [GlobalPersonMixin],
  data () {
    return {
      cache: {
        timestamp: null
      },
      fake: {
      },
      filters: {
        data1: null,
        data2: null,
        colaborador: null
      },
      gerando: false,
      resultado: null
    }
  },
  methods: {
    gerar () {
      const filters = JSON.parse(JSON.stringify(this.filters))
      filters.data1 = filters.data1 ? datePtToEn(filters.data1) : null
      filters.data2 = filters.data2 ? datePtToEn(filters.data2) : null
      filters.colaborador = filters.colaborador && filters.colaborador.id ? filters.colaborador.id : null
      this.$uloc.loading.show({message: 'Gerando relatório'})
      this.gerando = true
      let formato = 'json'
      relatorio('tempoTrabalho', filters, formato)
          .then(response => {
            this.$uloc.loading.hide()
            this.gerando = false
            if (formato === 'json' && response && response.data) {
              this.resultado = response.data
            }
          })
          .catch(error => {
            this.gerando = false
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    },
    clickPerson (person) {
      this.gotoPerson(person)
    },
    convertDate (d) {
      return d.replace(/(\d{4})(\d{2})(\d{2})/, '$3/$2/$1')
    },
    secondsToHours (s) {
      return formatDistance(0, s * 1000, {includeSeconds: true, locale: ptBRLocale})
    }
  },
  components: {
    GlobalPessoaInput,
    DatetimeModernInput,
    ErpSField,
    ECol,
    ERow,
    RelHeader,
  }
}
</script>
